.aspect-ratio-sixteen-nine {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.aspect-ratio-sixteen-nine::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 or 56.25% */
}

.aspect-ratio-sixteen-nine img {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset the position by half the image's width and height */
  width: auto; /* Maintain the original aspect ratio */
  height: 100%; /* Make sure the image covers the height fully */
  min-width: 100%; /* Ensure the image covers the width fully when necessary */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.aspect-ratio-sixteen-six {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.aspect-ratio-sixteen-six::before {
  content: '';
  display: block;
  padding-top: 37.5%; /* 9 / 16 = 0.5625 or 56.25% */
}

.aspect-ratio-sixteen-six img {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset the position by half the image's width and height */
  width: auto; /* Maintain the original aspect ratio */
  height: 100%; /* Make sure the image covers the height fully */
  min-width: 100%; /* Ensure the image covers the width fully when necessary */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.clip-item-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.aspect-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the image fits within the aspect ratio */
}

.aspect-ratio-content img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}

.floating-content {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 10; /* Ensure it's on top of the image */
}
