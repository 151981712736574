body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fill-height {
  height: -webkit-fill-available;
}

.reset-max-width {
  max-width: initial !important;
}

.collection-viewport {
  overflow-y: hidden;
  overflow-x: auto;
}
.collection-viewport .cards {
  margin: 0;
  display: inline-flex;
  flex-wrap: nowrap;
}

.collection-viewport > .cards > .card {
  width: 160px;
}

.collection-viewport::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.limit-screen {
  max-height: 80vh;
}
.fluid-page-body {
  margin: 10px 0;
}

.noMarg {
  width: 200px;
  margin: 0 !important;
  padding: 0 !important;
}

.fluid-page-body .ui.fluid.container {
  width: inherit;
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.fake-header {
  font-size: 1.28571429em;
  border: none;
  margin: calc(2rem - 0.14285714em) 0em 1rem;
  padding: 0em 0em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.28571429em;
  text-transform: none;
  color: rgba(255, 255, 255, 0.9);
}
.fake-header .field {
  padding: 0em 0em !important;
}

.fake-header .divider.text {
  text-decoration: underline;
}

.fake-header .dropdown.icon {
  float: inherit;
  padding-top: 5px;
  margin-left: 5px !important;
}

.flex-far-right {
  margin-left: auto !important;
}

.justify-center {
  justify-content: center;
}

.page-body {
  margin-top: 10px;
}

.vertical-spacing {
  margin-bottom: 10px;
}

.setcenter {
  text-align: center !important;
}

.card-img-fill {
  object-fit: cover;
  height: 180px;
  overflow: hidden;
}
.card-list-indent {
  padding-left: 25px;
}

.card-icon-x {
  position: absolute;
  right: 0;
  padding-top: 5px;
}

.element-card {
  overflow: auto;
}

.page-wrapper {
  padding-top: 50px;
}
.display-linebreak {
  white-space: pre-line;
  color: rgba(255, 255, 255, 0.87);
}

.display-linebreak img {
  max-width: 100%;
}

.full-height .field {
  height: 100%;
}

.scrollVertical {
  white-space: nowrap;
  overflow-x: auto;
}

.margin {
  margin: 2px !important;
}

.display-linebreak pre {
  word-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
}
.extra-content-card {
  color: rgba(0, 0, 0, 0.68);
}

.vertical_align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel__container {
  max-width: 550px;
  margin: auto;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 2%;
  padding-right: 2%;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  padding: 2%;
  overflow: auto;
}

.display-linebreak h1,
.h1 {
  font-size: 16px;
}
.display-linebreak h2,
.h2 {
  font-size: 15px;
}
.display-linebreak h3,
.h3 {
  font-size: 14px;
}
.display-linebreak h4,
.h4 {
  font-size: 13px;
}
.display-linebreak h5,
.h5 {
  font-size: 12px;
}
.display-linebreak h6,
.h6 {
  font-size: 11px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
